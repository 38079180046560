<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <div class="vx-row">
          <div class="vx-col w-1/2">
            <label class="vs-input--label">Validity</label>
            <Datepicker
              class="w-full"
              type="date"
              v-model="validFrom"
              :max="dateEnd"
              format="dd-MM-yyyy"
            />
          </div>
          <div class="vx-col w-1/2">
            <label class="vs-input--label">To</label>
            <Datepicker
              class="w-full"
              type="date"
              v-model="validTo"
              :min="dateStart"
              format="dd-MM-yyyy"
            />
          </div>
        </div>
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="option.territory"
          :allow-empty="true"
          placeholder="Type to search"
          track-by="id"
          @search-change="onSearchTerritory"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-col w-full mb-6">
      <div class="vx-row ml-4">
        <vs-button
          class="m-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-search"
          @click="onClickSearch"
        >
          Search
        </vs-button>
        <!-- v-if="$store.getters['user/hasPermissions']('create')" -->
        <vs-button
          class="m-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="tabCreate()"
          >Create</vs-button
        >
        <vs-button
          class="m-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-trash-2"
          @click="handleClear()"
          >Clear</vs-button
        >
      </div>
    </div>

    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          multiple
          v-model="comboID"
          :sst="true"
          :data="this.responseData.records"
          :max-items="params.length"
          :total="params.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.params.start }} - {{ this.params.end }} of
                  {{ this.params.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in params.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <!-- <vs-th width="5%"></vs-th> -->
            <!-- <vs-th
              ><vs-checkbox
                v-model="selectAll"
                @input="handleSelectAll"
              />ALL</vs-th
            > -->
            <vs-th sort-key="code">Action</vs-th>
            <vs-th sort-key="code">Code</vs-th>
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="valid_from">Valid From</vs-th>
            <vs-th sort-key="valid_to">Valid To</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '']"
            >
              <vs-td>
                <template>
                  <vx-tooltip text="Show Combo">
                    <feather-icon
                      icon="SearchIcon"
                      svgClasses="w-5 h-5 hover:text-success stroke-current"
                      class="ml-4"
                      @click="clickShow(tr)"
                    />
                  </vx-tooltip>
                  <!-- <vx-tooltip text="Edit Combo" v-if="getPermissionEdit()"> -->
                  <vx-tooltip text="Edit Combo">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickEdit(tr)"
                    />
                  </vx-tooltip>
                  <!-- <vx-tooltip text="Delete Combo">
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 hover:text-danger stroke-current"
                      class="ml-4"
                      @click="clickDelete(tr)"
                    />
                  </vx-tooltip> -->
                </template>
              </vs-td>
              <vs-td :data="data[indextr].code">{{ data[indextr].code }}</vs-td>

              <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
              <vs-td :data="data[indextr].valid_from">{{
                data[indextr].valid_from | formatDate
              }}</vs-td>
              <vs-td :data="data[indextr].valid_to">{{
                data[indextr].valid_to | formatDate
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="params.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  props: {
    selected: Object,
  },
  components: {
    Datepicker,
  },
  name: "list",
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      params: {
        length: 10,
        page: 1,
        search: "",
        order: "desc",
        sort: "id",
        total: 20,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        territory_id: null,
        validTo: "",
        validFrom: "",
      },
      header: [
        {
          text: "Action",
          sortable: false,
          width: "5%",
        },
        {
          text: "Code",
          value: "code",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Valid From",
          value: "valid_from",
        },
        {
          text: "Valid To",
          value: "valid_to",
        },
      ],
      responseData: {},
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      selectedData: {},
      validateAll: false,
      validate: [],
      option: {
        territory: [],
      },
      selectedTerritory: null,
      validFrom: null,
      validTo: null,
      comboID: [],
      IDItem: [],
      selectAll: false,
    };
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },
  methods: {
    // formatDates(dateStr) {
    //   const [day, month, year] = dateStr.split("-");
    //   const dateObj = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
    //   return dateObj.toISOString();
    // },
    getPermissionEdit(){
      // return $store.getters['user/hasPermissions']('edit')
    },
    handleClear() {
      let params = {
        ...this.params,
      };
      //reset filter
      params.selectedTerritory = null;
      params.validFrom = null;
      this.validFrom = null;
      params.validTo = null;
      this.validTo = null;
      this.reloadData(params);
    },
    onClickSearch() {
      let params = {
        ...this.params,
      };
      if (this.selectedTerritory) {
        params.territory_id = this.selectedTerritory.id;
      }
      if (this.validFrom) {
        params.validFrom = this.validFrom;
      }
      if (this.validTo) {
        params.validTo = this.validTo;
      }
      this.reloadData(params);
    },
    onSearchTerritory(query) {
      this.getDataTerritory({
        search: query,
        length: 10,
      });
    },
    getDataTerritory(params) {
      return this.$http
        .get("/api/v1/master/multiple-territory")
        .then((resp) => {
          this.option.territory = resp.data.records;
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    reloadData(params) {
      this.$vs.loading();
      if (this.selectedTerritory) {
        params.territory_id = this.selectedTerritory.id;
      }
      if (this.validFrom) {
        params.validFrom = this.validFrom;
      }
      if (this.validTo) {
        params.validTo = this.validTo;
      }
      this.$http
        .get("/api/v1/master/combosv2", {
          params: params,
        })
        .then((resp) => {
          if (resp.data) {
            if (resp.data.records.length != 0) {
              this.responseData = resp.data;
              this.params.total = resp.data.total_record;
              this.params.totalPage = resp.data.total_page;
              this.params.totalSearch = resp.data.total_record_search;
              this.params.length = resp.data.total_record_per_page;
              for (let index = 0; index < resp.data.records.length; index++) {
                this.responseData.records[index]["indextr"] = index;
                this.IDItem.push(this.responseData.records[index].id);
              }
            } else {
              console.log("Data Empty");

              this.responseData.records = [];
            }
          }

          this.setStartEnd();
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    clickDelete(tr) {
      this.$http.delete("api/v1/master/combo/" + tr.id).then((resp) => {
        if (resp.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: resp.message,
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.reloadData(this.params);
        } else {
          this.$vs.notify({
            title: "Danger",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
      });
      this.$vs.loading.close();
    },
    clickShow(tr) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      var option = this.option.territory.filter(function (territory) {
        return territory.ID == tr.TerritoryID;
      })[0];
      this.$emit("changeTab", 1, option, tr, false);
    },
    closeDetail() {
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    clickEdit(tr) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      var option = this.option.territory.filter(function (territory) {
        return territory.ID == tr.TerritoryID;
      })[0];
      this.$emit("changeTab", 1, option, tr, true);
    },
    tabCreate() {
      this.closeDetail();
      this.$emit("changeTab", 1, null, null, true);
    },
    handleSearch(searching) {
      this.params.search = searching;
      this.params.page = 1;
      this.responseData.records = [];
      this.reloadData(this.params);
    },
    handleChangePage(page) {
      this.params.page = page;
      this.reloadData(this.params);
    },
    handleSort(key, active) {
      this.params.order = key;
      this.params.sort = active;
      this.reloadData(this.params);
    },
    handleChangelength(val) {
      this.params.length = val == "All" ? this.params.total : val;
      this.params.page = 1;
      this.reloadData(this.params);
    },
    setStartEnd() {
      let valStart =
        this.params.length * this.params.page - this.params.length + 1;
      if (valStart > this.params.total) {
        valStart = 1;
      }
      if (this.params.total == 0) {
        valStart = 0;
      }
      let valEnd = this.params.length * this.params.page;
      if (valEnd > this.params.total) {
        valEnd = this.params.total;
      }
      if (this.params.totalSearch < this.params.total) {
        valEnd = this.params.totalSearch;
      }

      console.log("########", this.params.start);
      this.params.start = valStart;
      this.params.end = valEnd;
    },
    ExportForm() {
      if (this.comboID) {
        this.IDItem = [];
        for (let index = 0; index < this.comboID.length; index++) {
          this.IDItem.push(this.comboID[index].id);
        }
      }

      var fileTitle = "COMBO_MASTER_EXPORT_";
      // this.$vs.loading();
      this.$http
        .get("/api/v1/master/export-combo", {
          responseType: "arraybuffer",
          params: {
            selectedExport: this.IDItem.join(", "),
          },
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
          // this.$vs.loading.close();
        });
    },
  },
  mounted() {
    this.getDataTerritory();
    let params = {
      ...this.params,
    };
    this.responseData.records = [];
    this.reloadData(params);
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
